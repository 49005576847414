.contact-container {
    display: flex;
    flex-direction: column;
    width: auto;
    padding-top: 30px;

    h2 {
        margin: 10px 0;
        text-align: center;
        color: #442255;
    }
    .button-container {
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        flex: 1 auto;
    
        justify-content: space-between;
    
        padding-top: 5%;
    }
}