.cart-dropdown-container {
    position: absolute;
    min-width: 40%;
    max-width: 90%;
    height: auto;
    display: flex;
    flex-direction: column;
    padding: 20px;
    border: 2px solid black;
    background-color: white;
    top: 90px;
    right: 40px;
    z-index: 5;
    opacity: 0.95;
  
    .empty-message {
      font-size: 18px;
      margin: 50px auto;
    }
  
    .cart-items {
      height: 240px;
      display: flex;
      flex-direction: column;
      overflow: auto;
    }
  
    button {
      margin-top: auto;
      width: 40%;
    }
  }
  